import React from 'react';

import { Wrap, LineWrap, Line, Footer } from './styled';

function ContentComponent() {
  return (
    <Wrap>
      <h1>(주)스테이션3는 안전을 최우선적인 경영 가치로 삼아 산업재해 및 중대재해 예방을 위해 다음과 같이 선포한다.</h1>

      <LineWrap>
        <Line>
          <h1>하나,</h1>
          <div>
            <p>안전보건을 경영의 핵심 가치로 삼아</p>
            <p>안전보건경영체계 구축에 최선을 다한다.</p>
          </div>
        </Line>
  
        <Line>
          <h1>하나,</h1>
          <div>
            <p>철저한 안전보건 점검 및 관리를 통해</p>
            <p>안전하고 건강한 일터를 조성한다.</p>
          </div>
        </Line>
  
        <Line>
          <h1>하나,</h1>
          <div>
            <p>전사적인 안전문화 인식 개선 및 정착을 위해</p>
            <p>지속적인 교육 및 홍보를 적극 전개한다.</p>
          </div>
        </Line>
      </LineWrap>

      <Footer>
        <p>2022년 01월 19일</p>
        <p>(주) 스테이션3</p>
      </Footer>
    </Wrap>
  );
}

export default ContentComponent;
