import styled from 'styled-components';

import media from '~/utils/media'

export const Wrap = styled.div`
  padding: 80px 0 120px 0;

  ${media.desk1600`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}

  ${media.desk1200`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}

  > h1 {
    width: 100%;
    color: #000;
    font-size: 26px;
    line-height: 40px;
    word-break: keep-all;

    ${media.desk1600`
      margin: 0 auto;
      max-width: 600px;
      white-space: pre-line;
    `}

    ${media.desk1200`
      margin: 0 auto;
      max-width: 600px;
      white-space: pre-line;
    `}

    ${media.tabletB`
      max-width: 640px;
      white-space: pre-line;
    `}
    
    ${media.tablet`
      max-width: 520px;
      font-size: 21px;
      line-height: 30px;
      white-space: pre-line;
    `}
    
    ${media.mobile`
      font-size: 18px;
      line-height: 25px;
      white-space: pre-line;
    `}
    
    ${media.mobileS`
      font-size: 16px;
      line-height: 25px;
      white-space: pre-line;
    `}
  }
`;

export const LineWrap = styled.div`
  margin: 24px auto 80px;
`;

export const Line = styled.div`
  padding: 64px 0;

  > h1 {
    width: 30%;
    font-size: 30px;
    line-height: 45px;

    ${media.desk1600`
      white-space: pre-line;
    `}
    
    ${media.desk1200`
      white-space: pre-line;
    `}

    ${media.tabletB`
      white-space: pre-line;
    `}
    
    ${media.tablet`
      font-size: 24px;
      line-height: 30px;
      white-space: pre-line;
    `}
    
    ${media.mobile`
      font-size: 18px;
      line-height: 25px;
      white-space: pre-line;
    `}
    
    ${media.mobileS`
      font-size: 18px;
      line-height: 25px;
      white-space: pre-line;
    `}
  }

  p {
    font-size: 20px;
    line-height: 32px;

    ${media.desk1600`
      white-space: pre-line;
    `}
    
    ${media.desk1200`
      white-space: pre-line;
    `}

    ${media.tabletB`
      white-space: pre-line;
    `}
    
    ${media.tablet`
      white-space: pre-line;
    `}
    
    ${media.mobile`
      font-size: 18px;
      line-height: 24px;
      white-space: pre-line;
    `}
    
    ${media.mobileS`
      font-size: 18px;
      line-height: 24px;
      white-space: pre-line;
    `}
  }

  ${media.desk1600`
    display: flex;
    width: 850px;
  `}
  
  ${media.desk1200`
    display: flex;
    width: 850px;
  `}

  ${media.tabletB`
    display: block;
    width: 100%;
  `}
  
  ${media.tablet`
    display: block;
    width: 100%;
  `}
  
  ${media.mobile`
    display: block;
    width: 100%;
  `}
  
  ${media.mobileS`
    display: block;
    width: 100%;
  `}

  & + div {
    border-top: 1px solid #E3E1E1;
  }
`;

export const Footer = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;

  > p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    & + p {
      margin-top: 8px;
    }
  }
`;
