import React from 'react'

import { Banner, Img, Overlay, Title } from './styled'

const BannerComponent = () => {
  return (
    <Banner>
      <Img />
      <Overlay>
        <Title>안전·보건 경영방침</Title>
      </Overlay>
    </Banner>
  )
}

export default BannerComponent
